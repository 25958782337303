import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $, { data } from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import { ToastMsg } from "../pages/TostMsg";
import Modal from "react-bootstrap/Modal";
import ApiConfig, { FILE_BASE_URL } from "../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  multipartPutCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,

  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";

import FormLoader from "./FormLoader";
import { ScaleLoader } from "react-spinners";
import { Pagination } from "./Pagination";

const NoraaniQaaidah = ({ key }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [harfDropDown, setHarfDropDown] = useState([]);
  const [harfselect, setHarfSelect] = useState();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  let module_id;
  var course_yr_id;
  let category_id;

  if (typeof window !== undefined) {
    module_id = localStorage.getItem("module_id");
    course_yr_id = localStorage.getItem("course_yr_id");
    category_id = localStorage.getItem("category_id");
  }
  console.log("course_yr_id before addSabaqApi:", course_yr_id);
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [sabaqList, setSabaqList] = useState([]);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [viewDetails, setViewDetails] = useState({});
  const [idEdit, setIdEdit] = useState("");
  console.log("idEdit1 :", idEdit);
  const [deleteId, setDeleteId] = useState("");
  const [testEdit, setTestEdit] = useState("");
  const [viewSabaqList, setViewSabaqList] = useState([]);
  const [filterEdit, setFilterEdit] = useState({});
  const [saveBotton, setSaveBotton] = useState(false);
  const [imageWidth, setImageWidth] = useState({
    sabaq: "100%",
    des: "100%",
    Title: "100%",
    subdesc: "100%",
    mashq: "100%",
  });

  const [counter, setCounter] = useState(1);

  const [addSabaq, setAddSabaq] = useState({
    sabaqName: "",
    sabaqAudio: "",
    sabaqIcon: [],
    sabaqTeacherAudio: "",
    sabaqDesc: "",
    sabaqDescAudio: "",
    sabaqDescAudioWithoutChild: "",
    subSabaqs: [
      {
        subSabaqTitle: "",
        titleAudioPath: "",
        subSabaqDesc: "",
        descAudioPath: "",
        titleTeacherAudioPath: "",
        teacherDescAudioPath: "",
        mashqs: [
          {
            mashq: "",
            mashqAudioPath: "",
            teacherMashqAudioPath: "",
            isMurakkab: false,
            harfId: "",
            murakkab: "",
            isQuranMashq: false,
            waqf1: "",
            waqf2: "",
            waqf3: "",
            ayatNo: "",
          },
        ],
      },
    ],
  });
  console.log("addSabaq", addSabaq);
  console.log("mashqs,", addSabaq?.subSabaqs);
  const [AddNewGroup, setAddNewGroup] = useState(0);
  const [AddsabaqMashq, setAddsabaqMashq] = useState(0);
  const [add, setAdd] = useState(false);
  const handleAddClose = (test) => {
    setAdd(false);
    setIdEdit(test);

    setAddSabaq({
      sabaqName: "",
      sabaqAudio: "",
      sabaqTeacherAudio: "",
      sabaqDesc: "",
      sabaqDescAudio: "",
      sabaqDescAudioWithoutChild: "",
      sabaqIcon: [],
      subSabaqs: [
        {
          subSabaqTitle: "",
          titleAudioPath: "",
          subSabaqDesc: "",
          descAudioPath: "",
          titleTeacherAudioPath: "",
          teacherDescAudioPath: "",



          mashqs: [
            {
              mashq: "",
              mashqAudioPath: "",
              teacherMashqAudioPath: "",
              isMurakkab: "",
              harfId: "",
              isQuranMashq: "",
              waqf1: "",
              waqf2: "",
              waqf3: "",
              ayatNo: "",
            },
          ],
        },
      ],
    });
  };
  const handleAddShow = (set) => {
    if (set === "add") {
      setIdEdit("");
      setIsChecked(false);
      setAddSabaq({
        sabaqName: "",
        sabaqAudio: "",
        sabaqTeacherAudio: "",
        sabaqDesc: "",
        sabaqDescAudio: "",
        sabaqDescAudioWithoutChild: "",
        sabaqIcon: [],
        subSabaqs: [
          {
            subSabaqTitle: "",
            titleAudioPath: "",
            subSabaqDesc: "",
            descAudioPath: "",
            titleTeacherAudioPath: "",
            teacherDescAudioPath: "",
            mashqs: [
              {
                mashq: "",
                mashqAudioPath: "",
                teacherMashqAudioPath: "",
                isMurakkab: "",
                harfId: "",
                isQuranMashq: "",
                waqf1: "",
                waqf2: "",
                waqf3: "",
                ayatNo: "",
              },
            ],
          },
        ],
      });
    }
    setAdd(true);
  };

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => {
    setDelete(false);
  };
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);

  const handleViewShow = () => setView(true);
  const [isdeleteList, setIsDeleteList] = useState(false);
  const [validated, setValidated] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(sabaqList?.length / 15);




  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    console.log("Form Validity:", form.checkValidity());
    if (form.checkValidity() === false) {
      // event.preventDefault();
      event.stopPropagation();
    } else {
      addSabaqApi();
    }

    setValidated(true);
  };


  const addSabaqApi = () => {
    setSaveBotton(true);

    console.log("add data", addSabaq)

    // setLoader(true);
    const body = {
      moduleId: module_id,
      courseYrId: (module_id === 1 || module_id === 2) && course_yr_id,
      categoryId: 1,
      userId: Number(adminData.user_id),
      sabaqName: addSabaq.sabaqName,
      sabaqDesc: addSabaq.sabaqDesc,
      sabaqNo: lasatSabaqId + 1,
      sabaqId: addSabaq.SabaqId,
      // sabaqIcon: addSabaq?.sabaqIcon,
      // sabaqId:lsatSabaqId
    };
    let payLoadFormData = new FormData();
    payLoadFormData.append("moduleId", body.moduleId);
    payLoadFormData.append("courseYrId", course_yr_id);
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqName", body.sabaqName ? body.sabaqName : "");
    payLoadFormData.append("sabaqIcon", body.sabaqIcon ? body.sabaqIcon : "");
    // payLoadFormData.append("sabaqNo", body.sabaqNo);
    payLoadFormData.append("sabaqDesc", body.sabaqDesc ? body.sabaqDesc : "");

    let subSabaq =
      addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele, index) => {
        return {
          subSabaqTitle: ele.subSabaqTitle,
          subSabaqTitleAudio:
            ele.titleAudioPath == "" ? "no file" : "file name",
          subSabaqDescAudio: ele.descAudioPath == "" ? "no file" : "file name",
          subSabaqTitleAudioWithoutChild: ele.titleTeacherAudioPath == "" ? "no file" : "file name",
          subSabaqDescAudioWithoutChild: ele.teacherDescAudioPath == "" ? "no file" : "file name",
          subSabaqDesc: ele.subSabaqDesc,
          subSabaqNo: index + 1,
          mashqs: ele?.mashqs?.map((elesub) => {
            return {
              mashq: elesub.mashq,
              mashqAudio: elesub.mashqAudioPath == "" ? "no file" : "file name",
              mashqAudioWithoutChild: elesub.teacherMashqAudioPath == "" ? "no file" : "file name",
              isMurakkab: elesub.isMurakkab,
              isQuranMashq: elesub.isQuranMashq,
              harfId: elesub.harfId,
              murakkab: elesub.murakkab,
              waqf1: elesub.waqf1,
              waqf2: elesub.waqf2,
              waqf3: elesub.waqf3,
              ayatNo: elesub.ayatNo,
            };
          }),
        };
      });

    let subSabaqEDIT =
      addSabaq?.subSabaqs?.length > 0 &&
      addSabaq?.subSabaqs?.map((ele, index) => {
        console.log("update_data", ele);

        return {
          subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
          subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
          subSabaqTitleAudio:
            typeof ele.titleAudioPath == "object" ? "updated" : "not updated",
          subSabaqDescAudio:
            typeof ele.descAudioPath == "object" ? "updated" : "not updated",
          subSabaqTitleAudioWithoutChild: ele.titleTeacherAudioPath == "object" ? "updated" : "not updated",
          subSabaqDescAudioWithoutChild: ele.teacherDescAudioPath == "object" ? "updated" : "not updated",
          // subSabaqNo: ele.subSabaqNo,
          subSabaqNo: index + 1,

          subSabaqId: ele.subSabaqId,
          mashqs: ele.mashqs?.map((elesub) => {
            console.log("update_harf", elesub);
            return {
              mashq: elesub?.mashq ? elesub.mashq : "",
              mashqId: elesub.mashqId,
              mashqAudio:
                typeof elesub.mashqAudioPath == "object"
                  ? "updated"
                  : "not updated",
              mashqAudioWithoutChild: elesub.teacherMashqAudioPath == "object"
                ? "updated"
                : "not updated",
              isMurakkab: elesub.isMurakkab ? elesub.isMurakkab : "",
              isQuranMashq: elesub.isQuranMashq ? elesub.isQuranMashq : "",
              harfId: elesub.harfId ? elesub.harfId : elesub?.harf?.harf_id,
              murakkab: elesub.murakkab ? elesub.murakkab : "",
            };
          }),
        };
      });

    console.log("addSabaq at Submit:", addSabaq);
    console.log("subSabaqs Data:", addSabaq.subSabaqs);
    console.log("FormData before submission:", payLoadFormData);

    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEDIT))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaq));

    idEdit === "edit"
      ? payLoadFormData.append("sabaqId", body.sabaqId ? body.sabaqId : "")
      : payLoadFormData.append("sabaqNo", body.sabaqNo ? body.sabaqNo : "");




      let newSubaqAudio = [];
      newSubaqAudio.push(addSabaq.sabaqAudio);
  
      let newTeacherSubaqAudio = [];
      newTeacherSubaqAudio.push(addSabaq.sabaqTeacherAudio);
  
      let newSubaqDesceAudio = [];
      newSubaqDesceAudio.push(addSabaq.sabaqDescAudio);
      //descreption mashqAudioPath
      newSubaqDesceAudio.map((ele) =>
        payLoadFormData.append("sabaqDescAudio", ele ? ele : "")
      );
      let newTeacherSubaqDesceAudio = [];
      newTeacherSubaqDesceAudio.push(addSabaq.sabaqDescAudioWithoutChild);
  
      newTeacherSubaqDesceAudio.map((ele) =>
        payLoadFormData.append("sabaqDescAudioWithoutChild", ele ? ele : "")
      );
  
  
      newSubaqAudio.map((ele) =>
        payLoadFormData.append("sabaqNameAudio", ele ? ele : "")
      );
      newTeacherSubaqAudio.map((ele) =>
        payLoadFormData.append("sabaqNameAudioWithoutChild", ele ? ele : "")
      );

   addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele) =>
        payLoadFormData.append(
          "subSabaqTitleAudio",
          ele.titleAudioPath ? ele.titleAudioPath : ""
        )
      );
    //
   addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele) =>
        payLoadFormData.append(
          "subSabaqDescAudio",
          ele.descAudioPath ? ele.descAudioPath : ""
        )
      );
    addSabaq?.subSabaqs?.length > 0 &&
      addSabaq?.subSabaqs?.map(
        (ele, index) =>
          Array.isArray(ele.mashqs) && ele?.mashqs &&
          ele?.mashqs?.map((audiodata) =>
            payLoadFormData.append(
              "mashqAudio",
              audiodata.mashqAudioPath ? audiodata.mashqAudioPath : ""
            )
          )
      );
    addSabaq?.subSabaqs?.length > 0 &&
      addSabaq?.subSabaqs?.map(
        (ele, index) =>
            ele?.mashqs &&
          ele?.mashqs?.map((audiodata) =>
            payLoadFormData.append(
              "mashqAudioWithoutChild",
              audiodata.teacherMashqAudioPath ? audiodata.teacherMashqAudioPath : ""
            )
          )
      );


   addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele) =>
        payLoadFormData.append(
          "subSabaqDescAudioWithoutChild",
          ele.teacherDescAudioPath ? ele.teacherDescAudioPath : ""
        )
      );
   addSabaq?.subSabaqs?.length > 0 &&
      addSabaq.subSabaqs.map((ele) =>
        payLoadFormData.append(
          "subSabaqTitleAudioWithoutChild",
          ele.titleTeacherAudioPath ? ele.titleTeacherAudioPath : ""
        )
      );


    // const apiCall = idEdit === "edit"
    //   ? multipartPutCallWithErrorResponse(ApiConfig.UPDATE_SABAQ, payLoadFormData)
    //   : multipartPostCallWithErrorResponse(ApiConfig.ADD_SABAQ, payLoadFormData);

    // apiCall
    //     .then((res) => {
    //         setLoader(false);
    //         if (res.json && res.json?.success === true) {
    //             ToastMsg("success", res.json.message);
    //             SabaqListApi();
    //             setIdEdit("");
    //             handleAddClose()
    //         } else {
    //             ToastMsg("error", res.json.message);
    //         }
    //     })
    //     .catch((err) => {
    //         setLoader(false);
    //         ToastMsg("error", "Something went wrong"); // General error message
    //     })
    //     .finally(() => {
    //       setSaveBotton(false); // Re-enable button after operation completes
    //     });

    idEdit === "edit"
      ? multipartPutCallWithErrorResponse(
        ApiConfig.UPDATE_SABAQ,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);

            SabaqListApi();
            setIdEdit("");
            setSaveBotton(false);
            handleAddClose()
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          // ToastMsg("error", "something went wrong");
        })
      : // : multipartPostCallWithErrorResponse(ApiConfig.SUBAQADD, payLoadFormData)
      multipartPostCallWithErrorResponse(ApiConfig.ADD_SABAQ, payLoadFormData)
        .then((res) => {
          setLoader(false);

          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            handleAddClose();
            SabaqListApi();
            setIdEdit("");
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          // ToastMsg("error", "something went wrong");
        });
  };

  const SabaqListApi = () => {
    // const apiUrl = course_yr_id == null
    // ? ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${2}`
    // : ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${2}`;
    const apiUrl =
      module_id == 1 || module_id == 2
        ? ApiConfig.GET_ALL_SABAQ +
        `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${1}`
        : ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${1}`;
    simpleGetCallWithErrorResponse(apiUrl)
      .then((res) => {
        console.log("Sabak List Noorani", res);
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setSabaqList(res.json?.data.allSabaq);
          setLsatSabaq(res.json?.data.allSabaq.length);
          // pagination();
          // setLsatSabaqMash(
          //   res.json?.data.allSabaq[res.json.data?.allSabaq.length - 1]
          // );
          setAddSabaq([
            {
              sabaqName: "",
              sabaqAudio: "",
              sabaqDesc: "",
              sabaqDescAudio: "",
              sabaqTeacherDesc: '',
              sabaqTeacherAudio: '',
              sabaqIcon: [],
              subSabaqs: [
                {
                  subSabaqTitle: "",
                  titleAudioPath: "",
                  subSabaqDesc: "",
                  descAudioPath: "",
                  titleTeacherAudioPath: '',
                  teacherDescAudioPath: '',
                  mashqs: [
                    {
                      mashq: "",
                      mashqAudioPath: "",
                      teacherMashqAudioPath: '',
                      waqf1: "",
                      waqf2: "",
                      waqf3: "",
                      ayatNo: "",
                    },
                  ],
                },
              ],
            },
          ]);
        } else {
          // ToastMsg("error", res.json?.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  // Harf list Api
  const harfListApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.HARF_LIST)
      .then((res) => {
        console.log("API Response:", res);

        if (res.json && res.json.success === true) {
          console.log("HARF LIST", res.json.data);
          setHarfDropDown(res.json.data);
        } else {
          console.log("Harf Error. Response:", res.json);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  //subaqlist api
  useEffect(() => {
    SabaqListApi();
    harfListApi();
    console.log("harfList", harfDropDown);
    const harfIdValue = harfDropDown.length > 0 ? harfDropDown[0].harf_id : "";
    console.log("harfId", harfIdValue);
    setHarfSelect(harfIdValue);
  }, [key, module_id]);

  // useEffect(() => {
  //   sabaqList.length && pagination();
  // }, [sabaqList]);

  // useEffect(())
  const SabaqLisDeleteApi = () => {
    handleDeleteClose();
    const body = {
      categoryId: 1,
      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_SABAQ,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);

          SabaqListApi();

          // setIsDeleteList(true);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const viewDetailshandle = (id) => {
    // simpleGetCallWithErrorResponse(ApiConfig.EDIT + `?sabaqId=${id}`)
    simpleGetCallWithErrorResponse(
      ApiConfig.VIEW_ALL_SABAQ + `sabaqId=${id}&categoryId=${category_id}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const EditListApi = (id) => {
    console.log("SABAQ IDDDD", id);
    simpleGetCallWithErrorResponse(
      ApiConfig.VIEW_ALL_SABAQ + `sabaqId=${id}&categoryId=${category_id}`
    )
      .then((res) => {
        setLoader(false);

        // if (res.json && res.json?.success === true) {
        //   //  setAddSabaq(res.json.data);
        //   let data = res.json.data?.subSabaqs?.map((ele) => {
        //     console.log("data Edit", data)
        //     return {
        //       ...ele,
        //       mashqs: [
        //         {
        //           mashq: "",
        //           mashqAudioPath: "",
        //         },
        //       ],
        //     };
        //   });
        //   let newData = { ...res.json.data, subSabaqs: data };
        //   setAddSabaq(newData);

        //   handleAddShow();
        // } else {
        //   ToastMsg("error", res.json.message);
        // }
        if (res.json && res.json?.success === true) {
          setAddSabaq(res.json.data);
          console.log("DATA RECIVED :", res.json.data);

          handleAddShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  // <ScaleLoader
  //         color={"#004280"}
  //         loading={loader}
  //         style={{
  //           display: "block",
  //           textAlign: "center",
  //           margin: "20%",
  //           borderColor: "#b76b29",
  //         }}
  //         // css={override}
  //         size={300}
  //       />

  const getSabaqList = () => {
    // setIsLoading(true);

    course_yr_id
      ? simpleGetCallWithErrorResponse(
        ApiConfig.GET_ALL_SABAQ +
        `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${1}`
      )
      : simpleGetCallWithErrorResponse(
        ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${1}`
      )
        .then((res) => {
          // setLoader(false);
          console.log("GET_ALL_SABAQ response from server", res);
          if (res.json && res.json?.success === true) {
            // setsectionCatList(res.json.data)
            // sabaqList(res.json.data);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          // ToastMsg("error", "something went wrong");
        });
  };

  useEffect(() => {
    // getSabaqList();
  }, []);

  return (
    <>
      <main
        id="cx-main"
        className={sidebar ? "admin-main" : "cx-active admin-main"}
      >
        <div className="heading-btn-main">
          <div className="heading-common">
            <label htmlFor="">Noraani Qaaidah</label>
          </div>
          <div className="add-btn">
            <button onClick={() => handleAddShow("add")}>
              Add Sabaq
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
              </svg>
            </button>
          </div>
        </div>
        {/* ======================= START ADD ========================== */}
        <Modal
          show={add}
          onHide={() => handleAddClose()}
          size="lg"
          aria-labelledby="contained-modal-subSabaqTitle-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {idEdit === "edit"
                ? "Update Noraani Qaaidah"
                : "Add Noraani Qaaidah"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              loader ? <FormLoader /> :
                <Form
                  noValidate
                  validated={validated}
                  className="fw-bold"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <Form.Label>Sabaq Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="sabaqName"
                        value={addSabaq?.sabaqName}
                        onChange={(e) =>
                          setAddSabaq({ ...addSabaq, sabaqName: e.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>

                    {/* ADDD */}
                    {idEdit ? (
                      <>
                        <div className="row d-flex">
                          <div className="col-md-12">
                            <label htmlFor="" className="key">
                              Sabaq Audio(Teacher And Student)
                            </label>
                          </div>
                          <div
                            className={
                              imageWidth === "100%" ? "d-none" : "col-md-6 "
                            }
                          >
                            <Form.Label>
                              {typeof addSabaq?.sabaqAudio === "string"
                                ? addSabaq?.sabaqAudio.substring(
                                  addSabaq?.sabaqAudio.length - 15,
                                  addSabaq?.sabaqAudio.length
                                )
                                : ""}
                            </Form.Label>
                          </div>
                          <div className="col-md-12 mb-3 ">
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqAudio"
                              style={{ width: imageWidth.sabaq }}
                              //  value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setImageWidth({ ...imageWidth, sabaq: "100%" });
                                setAddSabaq({
                                  ...addSabaq,
                                  sabaqAudio: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12 mb-3">
                        <Form.Label>Sabaq Audio(Teacher And Student)</Form.Label>
                        <Form.Control
                          // required
                          type="file"
                          accept="audio/mp3,audio/*;capture=microphone"
                          name="sabaqAudio"
                          src={addSabaq?.sabaqAudio}
                          //  / value={addSabaq?.sabaqAudio}
                          onChange={(e) => {
                            setAddSabaq({
                              ...addSabaq,
                              sabaqAudio: e.target.files[0],
                            });
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                    )}
                    {idEdit ? (
                      <>
                        <div className="row d-flex">
                          <div className="col-md-12">
                            <label htmlFor="" className="key">
                              Sabaq Audio(Teacher)
                            </label>
                          </div>
                          <div
                            className={
                              imageWidth === "100%" ? "d-none" : "col-md-6 "
                            }
                          >
                            <Form.Label>
                              {typeof addSabaq?.sabaqTeacherAudio === "string"
                                ? addSabaq?.sabaqTeacherAudio.substring(
                                  addSabaq?.sabaqTeacherAudio.length - 15,
                                  addSabaq?.sabaqTeacherAudio.length
                                )
                                : ""}
                            </Form.Label>
                          </div>
                          <div className="col-md-12 mb-3 ">
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqAudio"
                              style={{ width: imageWidth.sabaq }}
                              //  value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setImageWidth({ ...imageWidth, sabaq: "100%" });
                                setAddSabaq({
                                  ...addSabaq,
                                  sabaqTeacherAudio: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12 mb-3">
                        <Form.Label>Sabaq Audio(Teacher)</Form.Label>
                        <Form.Control
                          // required
                          type="file"
                          accept="audio/mp3,audio/*;capture=microphone"
                          name="sabaqAudio"
                          src={addSabaq?.sabaqTeacherAudio}
                          //  / value={addSabaq?.sabaqAudio}
                          onChange={(e) => {
                            setAddSabaq({
                              ...addSabaq,
                              sabaqTeacherAudio: e.target.files[0],
                            });
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                    )}
                    {/* {idEdit ? (
                  <div>
                    <MDBRow>
                      <MDBCol lg="4" md="3" className="mb-4">
                        <img
                          src={
                            !addSabaq?.sabaqIcon
                              ? ""
                              : ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon &&
                                URL.createObjectURL(ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon)
                                
                          }
                          className="img-fluid rounded value"
                          alt=""
                        />
                      </MDBCol>
                    </MDBRow>
                  </div>
                ) : (
                  <div className="col-md-12 mb-3">
                    <Form.Label>Sabaq Icon</Form.Label>
                    <Form.Control
                      // required
                      type="file"
                      // accept="audio/mp3,audio/*;capture=microphone"
                      accept="image/*"
                      name="sabaqAudio"
                      src={addSabaq?.sabaqIcon}
                      //  / value={addSabaq?.sabaqAudio}
                      onChange={(e) => {
                        setAddSabaq({
                          ...addSabaq,
                          sabaqIcon: e.target.files[0],
                        });
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Icon
                    </Form.Control.Feedback>
                  </div>
                )} */}

                    {/* 16/01/2024 */}
                    {/* {idEdit ? ( */}
                    {/* // <div> */}
                    {/* <label>Sabaq Icon</label>
                    <MDBRow>
                      <MDBCol lg="1" md="1" sm="1" className="mb-4">
                        <img
                          src={ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon}
                          className="img-fluid rounded value"
                          alt=""
                        />
                      </MDBCol> */}
                    {/* </MDBRow> */}
                    {/* File input for updating the Sabaq Icon */}
                    {/* <div className="col-md-12 mb-3"> */}
                    {/* <Form.Label>Update Sabaq Icon</Form.Label> */}
                    {/* <Form.Control */}
                    {/* type="file" */}
                    {/* accept="image/*" */}
                    {/* name="updateSabaqIcon" */}
                    {/* onChange={(e) => { */}
                    {/* setAddSabaq({ */}
                    {/* ...addSabaq, */}
                    {/* sabaqIcon: e.target.files[0], */}
                    {/* }); */}
                    {/* }} */}
                    {/* /> */}
                    {/* <Form.Control.Feedback type="invalid"> */}
                    {/* Please Enter Sabaq Icon */}
                    {/* </Form.Control.Feedback> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* ) : ( */}
                    {/* <div className="col-md-12 mb-3"> */}
                    {/* File input for adding a new Sabaq Icon */}
                    {/* <Form.Label>Add Sabaq Icon</Form.Label> */}
                    {/* <Form.Control */}
                    {/* type="file" */}
                    {/* accept="image/*" */}
                    {/* name="sabaqIcon" */}
                    {/* onChange={(e) => { */}
                    {/* setAddSabaq({ */}
                    {/* ...addSabaq, */}
                    {/* sabaqIcon: e.target.files[0], */}
                    {/* }); */}
                    {/* }} */}
                    {/* /> */}
                    {/* <Form.Control.Feedback type="invalid"> */}
                    {/* Please Enter Sabaq Icon */}
                    {/* </Form.Control.Feedback> */}
                    {/* </div> */}
                    {/* )} */}

                    <div className="col-md-12 mb-3">
                      <Form.Label>Sabaq Description</Form.Label>
                      <Form.Control
                        // required
                        as="textarea"
                        rows={3}
                        name="sabaqDesc"
                        value={addSabaq?.sabaqDesc}
                        onChange={(e) =>
                          setAddSabaq({ ...addSabaq, sabaqDesc: e.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Tarjama.
                      </Form.Control.Feedback>
                    </div>
                    {/* <div className="col-md-12 mb-3">
                  <Form.Label>Description Audio</Form.Label>
                  <Form.Control
                    type="file"
                    name="sabaqDescAudio"
                    // value={addSabaq?.sabaqDescAudio}
                    onChange={(e) =>
                      setAddSabaq({
                        ...addSabaq,
                        sabaqDescAudio: e.target.files[0],
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div> */}
                    {idEdit ? (
                      <>
                        <div className="row d-flex">
                          <div className="col-md-12">
                            <label htmlFor="" className="key">
                              Sabaq Description Audio(Teacher And Student)
                            </label>
                          </div>
                          <div
                            className={
                              imageWidth === "100%" ? "invisible" : "col-md-6 "
                            }
                          >
                            <Form.Label>
                              {typeof addSabaq?.sabaqDescAudio === "string"
                                ? addSabaq?.sabaqDescAudio.substring(
                                  addSabaq?.sabaqDescAudio.length - 15,
                                  addSabaq?.sabaqDescAudio.length
                                )
                                : ""}
                            </Form.Label>
                          </div>
                          <div className="col-md-12 mb-3">
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqAudio"
                              style={{ width: imageWidth.des }}
                              //  value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setImageWidth({ ...imageWidth, des: "100%" });
                                setAddSabaq({
                                  ...addSabaq,
                                  sabaqDescAudio: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12 mb-3">
                        <Form.Label>Sabaq Description Audio(Teacher And Student) </Form.Label>
                        <Form.Control
                          // required
                          type="file"
                          accept="audio/mp3,audio/*;capture=microphone"
                          name="sabaqDescAudio"
                          src={addSabaq?.sabaqAudio}
                          //  / value={addSabaq?.sabaqAudio}
                          onChange={(e) => {
                            setAddSabaq({
                              ...addSabaq,
                              sabaqDescAudio: e.target.files[0],
                            });
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                    )}
                    {idEdit ? (
                      <>
                        <div className="row d-flex">
                          <div className="col-md-12">
                            <label htmlFor="" className="key">
                              Sabaq Description Audio(Teacher)
                            </label>
                          </div>
                          <div
                            className={
                              imageWidth === "100%" ? "invisible" : "col-md-6 "
                            }
                          >
                            <Form.Label>
                              {typeof addSabaq?.sabaqTeacherDesc === "string"
                                ? addSabaq?.sabaqTeacherDesc.substring(
                                  addSabaq?.sabaqTeacherDesc.length - 15,
                                  addSabaq?.sabaqTeacherDesc.length
                                )
                                : ""}
                            </Form.Label>
                          </div>
                          <div className="col-md-12 mb-3">
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqAudio"
                              style={{ width: imageWidth.des }}
                              //  value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setImageWidth({ ...imageWidth, des: "100%" });
                                setAddSabaq({
                                  ...addSabaq,
                                  sabaqDescAudioWithoutChild: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12 mb-3">
                        <Form.Label>Sabaq Description Audio(Teacher) </Form.Label>
                        <Form.Control
                          // required
                          type="file"
                          accept="audio/mp3,audio/*;capture=microphone"
                          name="sabaqDescAudio"
                          src={addSabaq?.sabaqDescAudioWithoutChild}
                          //  / value={addSabaq?.sabaqAudio}
                          onChange={(e) => {
                            setAddSabaq({
                              ...addSabaq,
                              sabaqDescAudioWithoutChild: e.target.files[0],
                            });
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                    )}
                    {addSabaq?.subSabaqs &&
                      addSabaq?.subSabaqs.map((item, index) => {
                        return (
                          <div
                            className="col-md-12 sub-group-main"
                            key={"noorani" + index}
                          >
                            <div className="row align-items-end">
                              <h6 className="fw-bold">
                                Sub Sabaq No. <span>{index + 1}</span>
                              </h6>
                              <div className="col-md-12 mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                  // required
                                  type="text"
                                  value={item?.subSabaqTitle}
                                  onChange={(e) => {
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subSabaqTitle: e.target.value,
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Choose Audio File.
                                </Form.Control.Feedback>
                              </div>
                              {/* <div className="col-md-12 mb-3">
                            <Form.Label>Title Audio</Form.Label>
                            <Form.Control
                              type="file"
                              // value={item?.titleAudioPath}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            titleAudioPath: e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Choose Audio File.
                            </Form.Control.Feedback>
                          </div> */}

                              {idEdit ? (
                                <>
                                  <div className="row d-flex">
                                    <div className="col-md-12">
                                      <label htmlFor="" className="key">
                                        Title Audio(Teacher And Student)
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        imageWidth === "100%"
                                          ? "invisible"
                                          : "col-md-6 "
                                      }
                                    >
                                      <Form.Label>
                                        {typeof item?.titleAudioPath === "string"
                                          ? item?.titleAudioPath.substring(
                                            item?.titleAudioPath.length - 15,
                                            item?.titleAudioPath.length
                                          )
                                          : ""}
                                      </Form.Label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="audio/mp3,audio/*;capture=microphone"
                                        name="sabaqAudio"
                                        style={{ width: imageWidth.Title }}
                                        //  value={addSabaq?.sabaqAudio}
                                        onChange={(e) => {
                                          setImageWidth({
                                            ...imageWidth,
                                            Title: "100%",
                                          });
                                          setAddSabaq({
                                            ...addSabaq,
                                            subSabaqs: addSabaq.subSabaqs.map(
                                              (item, innerIndex) => {
                                                return index === innerIndex
                                                  ? {
                                                    ...item,
                                                    titleAudioPath:
                                                      e.target.files[0],
                                                  }
                                                  : item;
                                              }
                                            ),
                                          });
                                        }}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Sabaq Name
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="col-md-12 mb-3">
                                  <Form.Label>Title Audio(Teacher And Student)</Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    onChange={(e) => {
                                      setAddSabaq({
                                        ...addSabaq,
                                        subSabaqs: addSabaq.subSabaqs.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                titleAudioPath: e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              )}
                              {idEdit ? (
                                <>
                                  <div className="row d-flex">
                                    <div className="col-md-12">
                                      <label htmlFor="" className="key">
                                        Title Audio(Teacher)
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        imageWidth === "100%"
                                          ? "invisible"
                                          : "col-md-6 "
                                      }
                                    >
                                      <Form.Label>
                                        {typeof item?.titleTeacherAudioPath === "string"
                                          ? item?.titleTeacherAudioPath.substring(
                                            item?.titleTeacherAudioPath.length - 15,
                                            item?.titleTeacherAudioPath.length
                                          )
                                          : ""}
                                      </Form.Label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="audio/mp3,audio/*;capture=microphone"
                                        name="sabaqAudio"
                                        style={{ width: imageWidth.Title }}
                                        //  value={addSabaq?.sabaqAudio}
                                        onChange={(e) => {
                                          setImageWidth({
                                            ...imageWidth,
                                            Title: "100%",
                                          });
                                          setAddSabaq({
                                            ...addSabaq,
                                            subSabaqs: addSabaq.subSabaqs.map(
                                              (item, innerIndex) => {
                                                return index === innerIndex
                                                  ? {
                                                    ...item,
                                                    titleTeacherAudioPath:
                                                      e.target.files[0],
                                                  }
                                                  : item;
                                              }
                                            ),
                                          });
                                        }}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Sabaq Name
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="col-md-12 mb-3">
                                  <Form.Label>Title Audio(Teacher)</Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    onChange={(e) => {
                                      setAddSabaq({
                                        ...addSabaq,
                                        subSabaqs: addSabaq.subSabaqs.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                titleTeacherAudioPath: e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              )}
                              <div className="col-md-12 mb-3">
                                <Form.Label>Sub Sabaq Description</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  value={item?.subSabaqDesc}
                                  onChange={(e) => {
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subSabaqDesc: e.target.value,
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Tarjama.
                                </Form.Control.Feedback>
                              </div>
                              {/* <div className="col-md-12 mb-3">
                            <Form.Label>Description Audio</Form.Label>
                            <Form.Control
                              type="file"
                              // value={item?.descAudioPath}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            descAudioPath: e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div> */}

                              {idEdit ? (
                                <>
                                  <div className="row d-flex">
                                    <div className="col-md-12">
                                      <label htmlFor="" className="key">
                                        Sub Sabaq Description Audio(Teacher And Student)
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        imageWidth === "100%"
                                          ? "invisible"
                                          : "col-md-6 "
                                      }
                                    >
                                      <Form.Label>
                                        {typeof item?.descAudioPath === "string"
                                          ? item?.descAudioPath.substring(
                                            item?.descAudioPath.length - 15,
                                            item?.descAudioPath.length
                                          )
                                          : ""}
                                      </Form.Label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="audio/mp3,audio/*;capture=microphone"
                                        name="sabaqAudio"
                                        style={{ width: imageWidth.subdesc }}
                                        //  value={addSabaq?.sabaqAudio}
                                        onChange={(e) => {
                                          setImageWidth({
                                            ...imageWidth,
                                            subdesc: "100%",
                                          });
                                          setAddSabaq({
                                            ...addSabaq,
                                            subSabaqs: addSabaq.subSabaqs.map(
                                              (item, innerIndex) => {
                                                return index === innerIndex
                                                  ? {
                                                    ...item,
                                                    descAudioPath:
                                                      e.target.files[0],
                                                  }
                                                  : item;
                                              }
                                            ),
                                          });
                                        }}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Sabaq Name
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="col-md-12 mb-3">
                                  <Form.Label>
                                    Sub Sabaq Description Audio(Teacher And Student)
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    onChange={(e) => {
                                      setAddSabaq({
                                        ...addSabaq,
                                        subSabaqs: addSabaq.subSabaqs.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                descAudioPath: e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              )}
                              {idEdit ? (
                                <>
                                  <div className="row d-flex">
                                    <div className="col-md-12">
                                      <label htmlFor="" className="key">
                                        Sub Sabaq Description Audio(Teacher)
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        imageWidth === "100%"
                                          ? "invisible"
                                          : "col-md-6 "
                                      }
                                    >
                                      <Form.Label>
                                        {typeof item?.teacherDescAudioPath === "string"
                                          ? item?.teacherDescAudioPath.substring(
                                            item?.teacherDescAudioPath.length - 15,
                                            item?.teacherDescAudioPath.length
                                          )
                                          : ""}
                                      </Form.Label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="audio/mp3,audio/*;capture=microphone"
                                        name="sabaqAudio"
                                        style={{ width: imageWidth.subdesc }}
                                        //  value={addSabaq?.sabaqAudio}
                                        onChange={(e) => {
                                          setImageWidth({
                                            ...imageWidth,
                                            subdesc: "100%",
                                          });
                                          setAddSabaq({
                                            ...addSabaq,
                                            subSabaqs: addSabaq.subSabaqs.map(
                                              (item, innerIndex) => {
                                                return index === innerIndex
                                                  ? {
                                                    ...item,
                                                    teacherDescAudioPath:
                                                      e.target.files[0],
                                                  }
                                                  : item;
                                              }
                                            ),
                                          });
                                        }}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Sabaq Name
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="col-md-12 mb-3">
                                  <Form.Label>
                                    Sub Sabaq Description Audio(Teacher)
                                  </Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    onChange={(e) => {
                                      setAddSabaq({
                                        ...addSabaq,
                                        subSabaqs: addSabaq.subSabaqs.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                teacherDescAudioPath: e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              )}
                              <div className="d-flex justify-content-between align-items-center mb-3 ">
                                <div className="">
                                  <h6 className="fw-bold ">Sabaq Mashq </h6>
                                </div>
                              </div>

                              <hr />

                              {item.mashqs &&
                                item.mashqs.map(
                                  (innerItem, innerIndex, allItems) => {
                                    return (
                                      <div
                                        className="row align-items-end"
                                        key={"masq" + index}
                                      >
                                        {innerItem.isQuranMashq ? (
                                          <div className="col-md-12 mb-3">
                                            <Form.Label>Aayat In Arabic</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={3}
                                              value={innerItem?.mashq}
                                              onChange={(e) => {
                                                setAddSabaq({
                                                  ...addSabaq,
                                                  subSabaqs: addSabaq.subSabaqs.map(
                                                    (innerItem, innerIndex1) => {
                                                      return index === innerIndex1
                                                        ? {
                                                          ...innerItem,
                                                          mashqs:
                                                            innerItem.mashqs.map(
                                                              (
                                                                innerItem2,
                                                                innerIndex2
                                                              ) => {
                                                                return innerIndex ===
                                                                  innerIndex2
                                                                  ? {
                                                                    ...innerItem2,
                                                                    mashq:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                  : innerItem2;
                                                              }
                                                            ),
                                                        }
                                                        : innerItem;
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              Please Enter Tarjama.
                                            </Form.Control.Feedback>
                                          </div>
                                        ) : (
                                          <div className="col-md-5 mb-3">
                                            <Form.Label> Mashq Arabic</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={innerItem?.mashq}
                                              onChange={(e) => {
                                                setAddSabaq({
                                                  ...addSabaq,
                                                  subSabaqs: addSabaq.subSabaqs.map(
                                                    (innerItem, innerIndex1) => {
                                                      return index === innerIndex1
                                                        ? {
                                                          ...innerItem,
                                                          mashqs:
                                                            innerItem.mashqs.map(
                                                              (
                                                                innerItem2,
                                                                innerIndex2
                                                              ) => {
                                                                return innerIndex ===
                                                                  innerIndex2
                                                                  ? {
                                                                    ...innerItem2,
                                                                    mashq:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                  : innerItem2;
                                                              }
                                                            ),
                                                        }
                                                        : innerItem;
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              Please Choose Audio File.
                                            </Form.Control.Feedback>
                                          </div>
                                        )}
                                        <div
                                          className="col-md-12 mb-3"
                                          style={{ paddingLeft: "46px" }}
                                        >
                                          <div className="formParent1">
                                            <div className="form-check form-switch">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                role="switch"
                                                value={innerItem?.isQuranMashq}
                                                checked={innerItem.isQuranMashq}
                                                onChange={(e) => {
                                                  setAddSabaq({
                                                    ...addSabaq,
                                                    subSabaqs:
                                                      addSabaq.subSabaqs.map(
                                                        (
                                                          innerItem,
                                                          innerIndex1
                                                        ) => {
                                                          return index ===
                                                            innerIndex1
                                                            ? {
                                                              ...innerItem,
                                                              mashqs:
                                                                innerItem.mashqs.map(
                                                                  (
                                                                    innerItem2,
                                                                    innerIndex2
                                                                  ) => {
                                                                    return innerIndex ===
                                                                      innerIndex2
                                                                      ? {
                                                                        ...innerItem2,
                                                                        isQuranMashq:
                                                                          index ===
                                                                          innerIndex1 &&
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                      }
                                                                      : innerItem2;
                                                                  }
                                                                ),
                                                            }
                                                            : innerItem;
                                                        }
                                                      ),
                                                  });
                                                }}
                                                id={`flexSwitchCheckChecked${innerIndex}`}
                                              />

                                              <label style={{ marginLeft: "46px" }}>
                                                Qur'aan Mashq
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        {!innerItem.isQuranMashq && (
                                          <>
                                            <div className="col-md-2 mb-3 mt-3">
                                              <div className="formParent">
                                                <div className="checkBox">
                                                  <div className="form-check form-switch">
                                                    <input
                                                      type="checkbox"
                                                      value={innerItem?.isMurakkab}
                                                      checked={innerItem.isMurakkab}
                                                      onChange={(e) => {
                                                        setAddSabaq({
                                                          ...addSabaq,
                                                          subSabaqs:
                                                            addSabaq.subSabaqs.map(
                                                              (
                                                                innerItem,
                                                                innerIndex1
                                                              ) => {
                                                                return index ===
                                                                  innerIndex1
                                                                  ? {
                                                                    ...innerItem,
                                                                    mashqs:
                                                                      innerItem.mashqs.map(
                                                                        (
                                                                          innerItem2,
                                                                          innerIndex2
                                                                        ) => {
                                                                          console.log(
                                                                            "index,",
                                                                            index
                                                                          );
                                                                          console.log(
                                                                            "innerIndex1,",
                                                                            innerIndex1
                                                                          );
                                                                          console.log(
                                                                            "innerIndex2,",
                                                                            innerIndex2
                                                                          );
                                                                          return innerIndex ===
                                                                            innerIndex2
                                                                            ? {
                                                                              ...innerItem2,
                                                                              isMurakkab:
                                                                                index ===
                                                                                innerIndex1 &&
                                                                                e
                                                                                  .target
                                                                                  .checked,
                                                                            }
                                                                            : innerItem2;
                                                                        }
                                                                      ),
                                                                  }
                                                                  : innerItem;
                                                              }
                                                            ),
                                                        });
                                                      }}
                                                      className="form-check-input"
                                                      role="switch"
                                                      id={`flexSwitchCheckChecked${innerIndex}`}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <>
                                              {/* mashaq */}
                                              {innerItem.isMurakkab &&
                                                index + innerIndex ===
                                                index + innerIndex && (
                                                  <div className=" col-md-2 mb-3">
                                                    <Form.Label className="mb-5">
                                                      mashq
                                                    </Form.Label>
                                                    <div className="formParent">
                                                      <div className="checkBox">
                                                        <div className="form-check form-check">
                                                          <input
                                                            type="checkbox"
                                                            value={
                                                              innerItem?.murakkab ===
                                                              2
                                                            }
                                                            checked={
                                                              innerItem.murakkab ===
                                                              2
                                                            }
                                                            onChange={(e) => {
                                                              setAddSabaq({
                                                                ...addSabaq,
                                                                subSabaqs:
                                                                  addSabaq.subSabaqs.map(
                                                                    (
                                                                      innerItem,
                                                                      innerIndex1
                                                                    ) => {
                                                                      return index ===
                                                                        innerIndex1
                                                                        ? {
                                                                          ...innerItem,
                                                                          mashqs:
                                                                            innerItem.mashqs.map(
                                                                              (
                                                                                innerItem2,
                                                                                innerIndex2
                                                                              ) => {
                                                                                console.log(
                                                                                  "index,",
                                                                                  index
                                                                                );
                                                                                console.log(
                                                                                  "innerIndex1,",
                                                                                  innerIndex1
                                                                                );
                                                                                console.log(
                                                                                  "innerIndex2,",
                                                                                  innerIndex2
                                                                                );
                                                                                return innerIndex ===
                                                                                  innerIndex2
                                                                                  ? {
                                                                                    ...innerItem2,
                                                                                    murakkab:
                                                                                      index ===
                                                                                        innerIndex1 &&
                                                                                        e
                                                                                          .target
                                                                                          .checked
                                                                                        ? 2
                                                                                        : null,
                                                                                  }
                                                                                  : innerItem2;
                                                                              }
                                                                            ),
                                                                        }
                                                                        : innerItem;
                                                                    }
                                                                  ),
                                                              });
                                                            }}
                                                            className="form-check-input"
                                                            role="checkbox"
                                                            id={`flexSwitchCheckChecked${innerIndex}`}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              {/* shakl */}
                                              {innerItem.isMurakkab &&
                                                index + innerIndex ===
                                                index + innerIndex && (
                                                  <div className=" col-md-2 mb-3">
                                                    <Form.Label className="mb-5">
                                                      Shakl
                                                    </Form.Label>
                                                    <div className="formParent">
                                                      <div className="checkBox">
                                                        <div className="form-check form-check">
                                                          <input
                                                            type="checkbox"
                                                            value={
                                                              innerItem?.murakkab ===
                                                              1
                                                            }
                                                            checked={
                                                              innerItem.murakkab ===
                                                              1
                                                            }
                                                            onChange={(e) => {
                                                              setAddSabaq({
                                                                ...addSabaq,
                                                                subSabaqs:
                                                                  addSabaq.subSabaqs.map(
                                                                    (
                                                                      innerItem,
                                                                      innerIndex1
                                                                    ) => {
                                                                      return index ===
                                                                        innerIndex1
                                                                        ? {
                                                                          ...innerItem,
                                                                          mashqs:
                                                                            innerItem.mashqs.map(
                                                                              (
                                                                                innerItem2,
                                                                                innerIndex2
                                                                              ) => {
                                                                                console.log(
                                                                                  "index,",
                                                                                  index
                                                                                );
                                                                                console.log(
                                                                                  "innerIndex1,",
                                                                                  innerIndex1
                                                                                );
                                                                                console.log(
                                                                                  "innerIndex2,",
                                                                                  innerIndex2
                                                                                );
                                                                                return innerIndex ===
                                                                                  innerIndex2
                                                                                  ? {
                                                                                    ...innerItem2,
                                                                                    murakkab:
                                                                                      index ===
                                                                                        innerIndex1 &&
                                                                                        e
                                                                                          .target
                                                                                          .checked
                                                                                        ? 1
                                                                                        : null,
                                                                                  }
                                                                                  : innerItem2;
                                                                              }
                                                                            ),
                                                                        }
                                                                        : innerItem;
                                                                    }
                                                                  ),
                                                              });
                                                            }}
                                                            className="form-check-input"
                                                            role="checkbox"
                                                            id={`flexSwitchCheckChecked${innerIndex}`}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}

                                              {/* Murrakab */}
                                              {idEdit ? (
                                                <div className=" col-md-3 mb-3">
                                                  <Form.Label>
                                                    Murakkab Harf
                                                  </Form.Label>
                                                  {innerItem.isMurakkab &&
                                                    index + innerIndex ===
                                                    index + innerIndex && (
                                                      <Form.Select
                                                        value={
                                                          innerItem?.harf?.harf_id
                                                        }
                                                        onChange={(e) => {
                                                          setAddSabaq({
                                                            ...addSabaq,
                                                            subSabaqs:
                                                              addSabaq.subSabaqs.map(
                                                                (
                                                                  innerItem,
                                                                  innerIndex1
                                                                ) => {
                                                                  return index ===
                                                                    innerIndex1
                                                                    ? {
                                                                      ...innerItem,
                                                                      mashqs:
                                                                        innerItem.mashqs.map(
                                                                          (
                                                                            innerItem2,
                                                                            innerIndex2
                                                                          ) => {
                                                                            return innerIndex ===
                                                                              innerIndex2
                                                                              ? {
                                                                                ...innerItem2,
                                                                                harfId:
                                                                                  index ===
                                                                                  innerIndex1 &&
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                              : innerItem2;
                                                                          }
                                                                        ),
                                                                    }
                                                                    : innerItem;
                                                                }
                                                              ),
                                                          });
                                                        }}
                                                      >
                                                        <option disabled selected>
                                                          Select Harf
                                                        </option>
                                                        {harfDropDown.length > 0
                                                          ? harfDropDown.map(
                                                            (item) => {
                                                              return (
                                                                <option
                                                                  key={
                                                                    item.harf_id
                                                                  }
                                                                  value={
                                                                    item.harf_id
                                                                  }
                                                                >
                                                                  {item.harf_name}
                                                                </option>
                                                              );
                                                            }
                                                          )
                                                          : null}
                                                      </Form.Select>
                                                    )}
                                                </div>
                                              ) : (
                                                <div className=" col-md-3 mb-3">
                                                  <Form.Label>
                                                    Murakkab Harf
                                                  </Form.Label>
                                                  {innerItem.isMurakkab &&
                                                    index + innerIndex ===
                                                    index + innerIndex && (
                                                      <Form.Select
                                                        onChange={(e) => {
                                                          setAddSabaq({
                                                            ...addSabaq,
                                                            subSabaqs:
                                                              addSabaq.subSabaqs.map(
                                                                (
                                                                  innerItem,
                                                                  innerIndex1
                                                                ) => {
                                                                  return index ===
                                                                    innerIndex1
                                                                    ? {
                                                                      ...innerItem,
                                                                      mashqs:
                                                                        innerItem.mashqs.map(
                                                                          (
                                                                            innerItem2,
                                                                            innerIndex2
                                                                          ) => {
                                                                            return innerIndex ===
                                                                              innerIndex2
                                                                              ? {
                                                                                ...innerItem2,
                                                                                harfId:
                                                                                  index ===
                                                                                  innerIndex1 &&
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                              : innerItem2;
                                                                          }
                                                                        ),
                                                                    }
                                                                    : innerItem;
                                                                }
                                                              ),
                                                          });
                                                        }}
                                                      >
                                                        <option disabled selected>
                                                          Select Harf
                                                        </option>

                                                        {harfDropDown.length > 0
                                                          ? harfDropDown.map(
                                                            (item) => {
                                                              return (
                                                                <option
                                                                  key={
                                                                    item.harf_id
                                                                  }
                                                                  value={
                                                                    item.harf_id
                                                                  }
                                                                >
                                                                  {item.harf_name}
                                                                </option>
                                                              );
                                                            }
                                                          )
                                                          : null}
                                                      </Form.Select>
                                                    )}
                                                </div>
                                              )}
                                            </>
                                          </>
                                        )}

                                        {/* Qur'aan Mashq */}

                                        {innerItem.isQuranMashq &&
                                          index + innerIndex ===
                                          index + innerIndex && (
                                            <div className="col-md-12 mb-1">
                                              <div className="row">
                                                <div className="col-md-4 fw-bold mb-3">
                                                  <Form.Label>Aayat No.</Form.Label>
                                                  <Form.Control
                                                    maxLength={10}
                                                    type="text"
                                                    value={innerItem?.ayatNo}
                                                    onChange={(e) => {
                                                      setAddSabaq({
                                                        ...addSabaq,
                                                        subSabaqs:
                                                          addSabaq.subSabaqs.map(
                                                            (
                                                              innerItem,
                                                              innerIndex1
                                                            ) => {
                                                              return index ===
                                                                innerIndex1
                                                                ? {
                                                                  ...innerItem,
                                                                  mashqs:
                                                                    innerItem.mashqs.map(
                                                                      (
                                                                        innerItem2,
                                                                        innerIndex2
                                                                      ) => {
                                                                        return innerIndex ===
                                                                          innerIndex2
                                                                          ? {
                                                                            ...innerItem2,
                                                                            ayatNo:
                                                                              index ===
                                                                              innerIndex1 &&
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          }
                                                                          : innerItem2;
                                                                      }
                                                                    ),
                                                                }
                                                                : innerItem;
                                                            }
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please Enter Aayat Number
                                                  </Form.Control.Feedback>
                                                </div>

                                                <div className="col-md-12"></div>

                                                <div className="col-md-4 mb-3">
                                                  <Form.Label>Waqf 1</Form.Label>
                                                  <Form.Control
                                                    maxLength={10}
                                                    type="text"
                                                    value={innerItem?.waqf1}
                                                    onChange={(e) => {
                                                      setAddSabaq({
                                                        ...addSabaq,
                                                        subSabaqs:
                                                          addSabaq.subSabaqs.map(
                                                            (
                                                              innerItem,
                                                              innerIndex1
                                                            ) => {
                                                              return index ===
                                                                innerIndex1
                                                                ? {
                                                                  ...innerItem,
                                                                  mashqs:
                                                                    innerItem.mashqs.map(
                                                                      (
                                                                        innerItem2,
                                                                        innerIndex2
                                                                      ) => {
                                                                        return innerIndex ===
                                                                          innerIndex2
                                                                          ? {
                                                                            ...innerItem2,
                                                                            waqf1:
                                                                              index ===
                                                                              innerIndex1 &&
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          }
                                                                          : innerItem2;
                                                                      }
                                                                    ),
                                                                }
                                                                : innerItem;
                                                            }
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please Choose Audio File.
                                                  </Form.Control.Feedback>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                  <Form.Label>Waqf 2</Form.Label>
                                                  <Form.Control
                                                    maxLength={10}
                                                    type="text"
                                                    value={innerItem?.waqf2}
                                                    onChange={(e) => {
                                                      setAddSabaq({
                                                        ...addSabaq,
                                                        subSabaqs:
                                                          addSabaq.subSabaqs.map(
                                                            (
                                                              innerItem,
                                                              innerIndex1
                                                            ) => {
                                                              return index ===
                                                                innerIndex1
                                                                ? {
                                                                  ...innerItem,
                                                                  mashqs:
                                                                    innerItem.mashqs.map(
                                                                      (
                                                                        innerItem2,
                                                                        innerIndex2
                                                                      ) => {
                                                                        return innerIndex ===
                                                                          innerIndex2
                                                                          ? {
                                                                            ...innerItem2,
                                                                            waqf2:
                                                                              index ===
                                                                              innerIndex1 &&
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          }
                                                                          : innerItem2;
                                                                      }
                                                                    ),
                                                                }
                                                                : innerItem;
                                                            }
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please Choose Audio File.
                                                  </Form.Control.Feedback>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                  <Form.Label>Waqf 3</Form.Label>
                                                  <Form.Control
                                                    maxLength={10}
                                                    type="text"
                                                    value={innerItem?.waqf3}
                                                    onChange={(e) => {
                                                      setAddSabaq({
                                                        ...addSabaq,
                                                        subSabaqs:
                                                          addSabaq.subSabaqs.map(
                                                            (
                                                              innerItem,
                                                              innerIndex1
                                                            ) => {
                                                              return index ===
                                                                innerIndex1
                                                                ? {
                                                                  ...innerItem,
                                                                  mashqs:
                                                                    innerItem.mashqs.map(
                                                                      (
                                                                        innerItem2,
                                                                        innerIndex2
                                                                      ) => {
                                                                        return innerIndex ===
                                                                          innerIndex2
                                                                          ? {
                                                                            ...innerItem2,
                                                                            waqf3:
                                                                              index ===
                                                                              innerIndex1 &&
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          }
                                                                          : innerItem2;
                                                                      }
                                                                    ),
                                                                }
                                                                : innerItem;
                                                            }
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please Choose Audio File.
                                                  </Form.Control.Feedback>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                        {/* <div className="col-md-5 mb-3">
                                    <Form.Label>Audio</Form.Label>
                                    <Form.Control
                                      type="file"
                                      // value={innerItem?.mashqAudioPath}
                                      onChange={(e) => {
                                        setAddSabaq({
                                          ...addSabaq,
                                          subSabaqs: addSabaq.subSabaqs.map(
                                            (innerItem, innerIndex1) => {
                                              return index === innerIndex1
                                                ? {
                                                    ...innerItem,
                                                    mashqs:
                                                      innerItem.mashqs.map(
                                                        (
                                                          innerItem2,
                                                          innerIndex2
                                                        ) => {
                                                          return innerIndex ===
                                                            innerIndex2
                                                            ? {
                                                                ...innerItem2,
                                                                mashqAudioPath:
                                                                  e.target
                                                                    .files[0],
                                                              }
                                                            : innerItem2;
                                                        }
                                                      ),
                                                  }
                                                : innerItem;
                                            }
                                          ),
                                        });
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Choose Audio File.
                                    </Form.Control.Feedback>
                                  </div> */}

                                        {idEdit ? (
                                          <>
                                            <div className="row d-flex">
                                              <div className="col-md-12">
                                                <label htmlFor="" className="key">
                                                  {innerItem.isQuranMashq
                                                    ? "Aayat Audio(Teacher And Student)"
                                                    : "Mashq Audio(Teacher And Student)"}
                                                </label>
                                              </div>
                                              <div
                                                className={
                                                  imageWidth === "100%"
                                                    ? "invisible"
                                                    : "col-md-6 "
                                                }
                                              >
                                                <Form.Label>
                                                  {typeof innerItem?.mashqAudioPath ===
                                                    "string"
                                                    ? innerItem?.mashqAudioPath.substring(
                                                      innerItem?.mashqAudioPath
                                                        .length - 15,
                                                      innerItem?.mashqAudioPath
                                                        .length
                                                    )
                                                    : ""}
                                                </Form.Label>
                                              </div>
                                              <div className="col-md-12 mb-3">
                                                <Form.Control
                                                  type="file"
                                                  accept="audio/mp3,audio/*;capture=microphone"
                                                  name="sabaqAudio"
                                                  style={{
                                                    width: imageWidth.mashq,
                                                  }}
                                                  //  value={addSabaq?.sabaqAudio}
                                                  onChange={(e) => {
                                                    setImageWidth({
                                                      ...imageWidth,
                                                      mashq: "100%",
                                                    });
                                                    setAddSabaq({
                                                      ...addSabaq,
                                                      subSabaqs:
                                                        addSabaq.subSabaqs.map(
                                                          (
                                                            innerItem,
                                                            innerIndex1
                                                          ) => {
                                                            return index ===
                                                              innerIndex1
                                                              ? {
                                                                ...innerItem,
                                                                mashqs:
                                                                  innerItem.mashqs.map(
                                                                    (
                                                                      innerItem2,
                                                                      innerIndex2
                                                                    ) => {
                                                                      return innerIndex ===
                                                                        innerIndex2
                                                                        ? {
                                                                          ...innerItem2,
                                                                          mashqAudioPath:
                                                                            e
                                                                              .target
                                                                              .files[0],
                                                                        }
                                                                        : innerItem2;
                                                                    }
                                                                  ),
                                                              }
                                                              : innerItem;
                                                          }
                                                        ),
                                                    });
                                                  }}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                  Please Enter Sabaq Name
                                                </Form.Control.Feedback>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="col-md-12 mb-3">
                                            <Form.Label>
                                              {innerItem.isQuranMashq
                                                ? "Aayat Audio(Teacher And Student)"
                                                : "Mashq Audio(Teacher And Student)"}
                                            </Form.Label>
                                            <Form.Control
                                              type="file"
                                              accept="audio/mp3,audio/*;capture=microphone"
                                              onChange={(e) => {
                                                setAddSabaq({
                                                  ...addSabaq,
                                                  subSabaqs: addSabaq.subSabaqs.map(
                                                    (innerItem, innerIndex1) => {
                                                      return index === innerIndex1
                                                        ? {
                                                          ...innerItem,
                                                          mashqs:
                                                            innerItem.mashqs.map(
                                                              (
                                                                innerItem2,
                                                                innerIndex2
                                                              ) => {
                                                                return innerIndex ===
                                                                  innerIndex2
                                                                  ? {
                                                                    ...innerItem2,
                                                                    mashqAudioPath:
                                                                      e.target
                                                                        .files[0],
                                                                  }
                                                                  : innerItem2;
                                                              }
                                                            ),
                                                        }
                                                        : innerItem;
                                                    }
                                                  ),
                                                });
                                              }}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                              Please Enter Sabaq Name
                                            </Form.Control.Feedback>
                                          </div>
                                        )}
                                        {idEdit ? (
                                          <>
                                            <div className="row d-flex">
                                              <div className="col-md-12">
                                                <label htmlFor="" className="key">
                                                  {innerItem.isQuranMashq
                                                    ? "Aayat Audio(Teacher)"
                                                    : "Mashq Audio(Teacher)"}
                                                </label>
                                              </div>
                                              <div
                                                className={
                                                  imageWidth === "100%"
                                                    ? "invisible"
                                                    : "col-md-6 "
                                                }
                                              >
                                                <Form.Label>
                                                  {typeof innerItem?.teacherMashqAudioPath ===
                                                    "string"
                                                    ? innerItem?.teacherMashqAudioPath.substring(
                                                      innerItem?.teacherMashqAudioPath
                                                        .length - 15,
                                                      innerItem?.teacherMashqAudioPath
                                                        .length
                                                    )
                                                    : ""}
                                                </Form.Label>
                                              </div>
                                              <div className="col-md-12 mb-3">
                                                <Form.Control
                                                  type="file"
                                                  accept="audio/mp3,audio/*;capture=microphone"
                                                  name="sabaqAudio"
                                                  style={{
                                                    width: imageWidth.mashq,
                                                  }}
                                                  //  value={addSabaq?.sabaqAudio}
                                                  onChange={(e) => {
                                                    setImageWidth({
                                                      ...imageWidth,
                                                      mashq: "100%",
                                                    });
                                                    setAddSabaq({
                                                      ...addSabaq,
                                                      subSabaqs:
                                                        addSabaq.subSabaqs.map(
                                                          (
                                                            innerItem,
                                                            innerIndex1
                                                          ) => {
                                                            return index ===
                                                              innerIndex1
                                                              ? {
                                                                ...innerItem,
                                                                mashqs:
                                                                  innerItem.mashqs.map(
                                                                    (
                                                                      innerItem2,
                                                                      innerIndex2
                                                                    ) => {
                                                                      return innerIndex ===
                                                                        innerIndex2
                                                                        ? {
                                                                          ...innerItem2,
                                                                          teacherMashqAudioPath:
                                                                            e
                                                                              .target
                                                                              .files[0],
                                                                        }
                                                                        : innerItem2;
                                                                    }
                                                                  ),
                                                              }
                                                              : innerItem;
                                                          }
                                                        ),
                                                    });
                                                  }}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                  Please Enter Sabaq Name
                                                </Form.Control.Feedback>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="col-md-12 mb-3">
                                            <Form.Label>
                                              {innerItem.isQuranMashq
                                                ? "Aayat Audio(Teacher)"
                                                : "Mashq Audio(Teacher)"}
                                            </Form.Label>
                                            <Form.Control
                                              type="file"
                                              accept="audio/mp3,audio/*;capture=microphone"
                                              onChange={(e) => {
                                                setAddSabaq({
                                                  ...addSabaq,
                                                  subSabaqs: addSabaq.subSabaqs.map(
                                                    (innerItem, innerIndex1) => {
                                                      return index === innerIndex1
                                                        ? {
                                                          ...innerItem,
                                                          mashqs:
                                                            innerItem.mashqs.map(
                                                              (
                                                                innerItem2,
                                                                innerIndex2
                                                              ) => {
                                                                return innerIndex ===
                                                                  innerIndex2
                                                                  ? {
                                                                    ...innerItem2,
                                                                    teacherMashqAudioPath:
                                                                      e.target
                                                                        .files[0],
                                                                  }
                                                                  : innerItem2;
                                                              }
                                                            ),
                                                        }
                                                        : innerItem;
                                                    }
                                                  ),
                                                });
                                              }}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                              Please Enter Sabaq Name
                                            </Form.Control.Feedback>
                                          </div>
                                        )}
                                        <div className="col-2 mb-3 minus-btn">
                                          <Link
                                            to="#"
                                            className={
                                              item.mashqs.length > 1
                                                ? "btn btn-danger"
                                                : "btn btn-secondary invisible"
                                            }
                                            // onClick={() =>
                                            //   setAddsabaqMashq(AddsabaqMashq - 1)
                                            // }
                                            onClick={() => {
                                              if (item.mashqs.length > 1) {
                                                item.mashqs.splice(innerIndex, 1);

                                                const copySubDeatails = [
                                                  ...item.mashqs,
                                                ];
                                              }

                                              // const copySubDeatails = [...addSabaq.subSabaqs];
                                              // copySubDeatails.sli
                                            }}
                                          //                                       onClick={() => {
                                          // let mashqList = [...item.mashqs]
                                          // mashqList.splice(innerIndex, 1)

                                          //                                       }}

                                          //   onClick={() => {
                                          // if (addSabaq.subSabaqs.length > 1) {
                                          //   addSabaq.subSabaqs.splice(index, 1);
                                          //   const copySubDeatails = [...addSabaq.subSabaqs];
                                          //   copySubDeatails.splice(index, 1);
                                          //   setAddSabaq({
                                          //     ...addSabaq,
                                          //     subSabaqs: copySubDeatails,
                                          //   });
                                          // }

                                          //
                                          >
                                            -
                                          </Link>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                              <hr />
                              <div className="plus-btn text-end">
                                <Link
                                  to="#"
                                  className={
                                    // idEdit
                                    // ? "pe-none btn  btn-secondary"
                                    // :
                                    "btn btn-success"
                                  }
                                  onClick={() => {
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, i) => {
                                          if (i === index) {
                                            let mashqs = [...item.mashqs];
                                            mashqs.push({
                                              mashq: "",
                                              mashqAudioPath: "",
                                              isMurakkab: "",
                                              harfId: "",
                                            });
                                            return {
                                              ...item,
                                              mashqs,
                                            };
                                          } else return item;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  Add+
                                </Link>
                              </div>
                            </div>
                            <br />
                            <div className="cmb-3 minus-btn text-end">
                              <Link
                                to="#"
                                className={
                                  addSabaq.subSabaqs.length > 1
                                    ? "btn btn-danger"
                                    : "btn btn-secondary invisible"
                                }
                                // onClick={() => setAddNewGroup(AddNewGroup - 1)}
                                onClick={() => {
                                  if (addSabaq.subSabaqs.length > 1) {
                                    // addSabaq.subSabaqs.splice(index, 1);
                                    const copySubDeatails = [...addSabaq.subSabaqs];
                                    copySubDeatails.splice(index, 1);
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: copySubDeatails,
                                    });
                                  }
                                  // const copySubDeatails = [...addSabaq.subSabaqs];
                                  // copySubDeatails.sli
                                }}
                              >
                                Remove
                              </Link>
                            </div>
                          </div>
                        );
                      })}

                    <Link
                      to="#"
                      className={
                        // idEdit
                        // ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                        // :
                        "add-newSabaq-btn btn btn-primary"
                      }
                      // onClick={() => setAddNewGroup(AddNewGroup + 1)}
                      onClick={() => {
                        let sabak = [
                          ...addSabaq.subSabaqs,

                          {
                            subSabaqTitle: "",
                            titleAudioPath: "",
                            subSabaqDesc: "",
                            sabaqTeacherAudio: "",
                            sabaqTeacherDesc: "",
                            subSabaqDescAudioWithoutChild: "",
                            descAudioPath: "",
                            mashqs: [
                              {
                                mashq: "",
                                mashqAudioPath: "",
                                mashqAudioWithoutChild: "",
                                isMurakkab: "",
                                harfId: "",
                              },
                            ],
                          },
                        ];
                        setAddSabaq({
                          ...addSabaq,
                          subSabaqs: sabak,
                        });
                      }}
                    >
                      + Add Sub Sabaq Details
                    </Link>
                  </div>
                  <div className="buttons-modal border-top pt-3 text-end ">
                    <button className="me-2" type="submit">
                      {idEdit === "edit" ? "update" : "Save"}
                    </button>
                    <Link
                      to="#"
                      className=" bg-danger"
                      onClick={() => {
                        setAddSabaq({
                          sabaqName: "",
                          sabaqAudio: "",
                          sabaqDesc: "",
                          sabaqDescAudio: "",
                          subSabaqs: [
                            {
                              subSabaqTitle: "",
                              titleAudioPath: "",
                              subSabaqDesc: "",
                              descAudioPath: "",
                              mashqs: [
                                {
                                  mashq: "",
                                  mashqAudioPath: "",
                                  isMurakkab: "",
                                  harfId: "",
                                },
                              ],
                            },
                          ],
                        });
                        // setTestEdit(addSabaq)
                        handleAddClose();
                        setIdEdit("");
                      }}
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
            }

          </Modal.Body>
        </Modal>
        {/* ======================= END ADD ========================== */}

        {/* ======================= START DELETE ========================== */}
        <Modal
          show={deleteModal}
          onHide={handleDeleteClose}
          size="sm"
          aria-labelledby="contained-modal-subSabaqTitle-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-msg text-center">
              <label htmlFor="">
                Are you sure you want to delete this item?
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-mashq-center">
            <div className="buttons-modal    ">
              <button
                className="me-2 bg-danger"
                onClick={() => {
                  handleDeleteClose();
                  SabaqLisDeleteApi();
                }}
              >
                Delete
              </button>
              <Link to="#" className="" onClick={handleDeleteClose}>
                Cancel
              </Link>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ======================= END DELETE ========================== */}

        {/* ======================= START VIEW ========================== */}

        <Modal
          show={viewModal}
          onHide={handleViewClose}
          size="xl"
          aria-labelledby="contained-modal-subSabaqTitle-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Noraani Qaaidah Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Sabaq No.
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="value">
                      {viewDetails?.SabaqNo}
                    </label>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div>
                    {/* <label htmlFor="" className="key">
                      Sabaq Icon
                    </label> */}
                  </div>
                  {/* <div>
                    <MDBRow>
                      <MDBCol lg="2" md="3" className="mb-4">
                        <img
                          src={
                            !viewDetails?.sabaqIcon
                              ? ""
                              : ApiConfig.FILE_BASE_URL +
                                viewDetails?.sabaqIcon.length
                                ? ApiConfig.FILE_BASE_URL + viewDetails?.sabaqIcon
                                : ApiConfig.FILE_BASE_URL +
                                viewDetails?.sabaqIcon &&
                                URL.createObjectURL(
                                  ApiConfig.FILE_BASE_URL +
                                  viewDetails?.sabaqIcon
                                )
                          }
                          className="img-fluid rounded value"
                          alt=""
                        />
                      </MDBCol>
                    </MDBRow>
                  </div> */}
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Sabaq Name
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="value">
                      {viewDetails?.sabaqName}
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-2">
                    <label htmlFor="" className="key">
                      Sabaq Audio(Teacher And Student)
                    </label>
                  </div>
                  <div>
                    {/* <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        src={`${FILE_BASE_URL}${viewDetails.sabaqAudio}`}
                        type="audio/ogg"
                      />
                      <source
                        src={`${FILE_BASE_URL}${viewDetails.sabaqAudio}`}
                        type="audio/mpeg"
                      />
                    </audio> */}
                    {viewDetails?.sabaqAudio ? (
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source
                          src={`${FILE_BASE_URL}${viewDetails.sabaqAudio}`}
                          type="audio/ogg"
                        />
                        <source
                          src={`${FILE_BASE_URL}${viewDetails.sabaqAudio}`}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6 mb-3"></div>
                <div className="col-md-6 mb-3">
                  <div className="mb-2">
                    <label htmlFor="" className="key">
                      Sabaq Audio(Teacher And Student)
                    </label>
                  </div>
                  <div>
                    {/* <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        src={`${FILE_BASE_URL}${viewDetails.sabaqAudio}`}
                        type="audio/ogg"
                      />
                      <source
                        src={`${FILE_BASE_URL}${viewDetails.sabaqAudio}`}
                        type="audio/mpeg"
                      />
                    </audio> */}
                    {viewDetails?.sabaqTeacherAudio ? (
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source
                          src={`${FILE_BASE_URL}${viewDetails.sabaqTeacherAudio}`}
                          type="audio/ogg"
                        />
                        <source
                          src={`${FILE_BASE_URL}${viewDetails.sabaqTeacherAudio}`}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Sabaq Description
                    </label>
                  </div>
                  <div>
                    <label htmlFor="" className="value">
                      {viewDetails?.sabaqDesc}
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-2">
                    <label htmlFor="" className="key">
                      Sabaq Description Audio(Teacher And Student)
                    </label>
                  </div>
                  <div>
                    {viewDetails?.sabaqDescAudio ? (
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source
                          // src={viewDetails?.sabaqDescAudio}
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqDescAudio}`}
                          type="audio/ogg"
                        />
                        <source
                          // src={viewDetails?.sabaqDescAudio}
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqDescAudio}`}
                          type="audio/mpeg"
                        />
                        Your browser does not support the mashqAudioPath
                        element.
                      </audio>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6 mb-3"></div>
                <div className="col-md-6 mb-3">
                  <div className="mb-2">
                    <label htmlFor="" className="key">
                      Sabaq Description Audio(Teacher)
                    </label>
                  </div>
                  <div>
                    {viewDetails?.sabaqTeacherDesc ? (
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source
                          // src={viewDetails?.sabaqTeacherDesc}
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherDesc}`}
                          type="audio/ogg"
                        />
                        <source
                          // src={viewDetails?.sabaqTeacherDesc}
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherDesc}`}
                          type="audio/mpeg"
                        />
                        Your browser does not support the mashqAudioPath
                        element.
                      </audio>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {viewDetails.subSabaqs && viewDetails.subSabaqs.length > 0
                  ? viewDetails?.subSabaqs.map((element, index) => {
                    return (
                      <div
                        className="sub-group-main row mb-3"
                        key={"testkey" + index}
                      >
                        <h6 className="fw-bold mb-4">
                          Sub Sabaq No {index + 1}
                        </h6>
                        <div className="col-md-6 mb-3">
                          <div>
                            <label htmlFor="" className="key">
                              Title
                            </label>
                          </div>
                          <div>
                            <label htmlFor="" className="value">
                              {element?.subSabaqTitle}
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div>
                            <label htmlFor="" className="key">
                              Title Audio(Teacher And Student)

                            </label>
                          </div>
                          <div>
                            {element?.titleAudioPath ? (
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  // src={element?.titleAudioPath}
                                  src={`${FILE_BASE_URL}${element?.titleAudioPath}`}
                                  type="audio/ogg"
                                />
                                <source
                                  // src={element?.titleAudioPath}
                                  src={`${FILE_BASE_URL}${element?.titleAudioPath}`}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the
                                mashqAudioPath element.
                              </audio>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                        </div>
                        <div className="col-md-6 mb-3">
                          <div>
                            <label htmlFor="" className="key">
                              Title Audio(Teacher)

                            </label>
                          </div>
                          <div>
                            {element?.titleTeacherAudioPath ? (
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  // src={element?.titleTeacherAudioPath}
                                  src={`${FILE_BASE_URL}${element?.titleTeacherAudioPath}`}
                                  type="audio/ogg"
                                />
                                <source
                                  // src={element?.titleTeacherAudioPath}
                                  src={`${FILE_BASE_URL}${element?.titleTeacherAudioPath}`}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the
                                mashqAudioPath element.
                              </audio>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="mb-2">
                            <label htmlFor="" className="key">
                              Sub Sabaq Description
                            </label>
                          </div>
                          <div className="mb-2">
                            <label htmlFor="" className="value">
                              {element?.subSabaqDesc}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="mb-2">
                            <label htmlFor="" className="key">
                              Sub Sabaq Description Audio(Teacher And Student)
                            </label>
                          </div>
                          <div>
                            {element?.descAudioPath ? (
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  // src={element?.descAudioPath}
                                  src={`${FILE_BASE_URL}${element?.descAudioPath}`}
                                  type="audio/ogg"
                                />
                                <source
                                  // src={element?.descAudioPath}
                                  src={`${FILE_BASE_URL}${element?.descAudioPath}`}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the
                                mashqAudioPath element.
                              </audio>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3"></div>
                        <div className="col-md-6 mb-3">
                          <div className="mb-2">
                            <label htmlFor="" className="key">
                              Sub Sabaq Description Audio(Teacher )
                            </label>
                          </div>
                          <div>
                            {element?.teacherDescAudioPath ? (
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  // src={element?.teacherDescAudioPath}
                                  src={`${FILE_BASE_URL}${element?.teacherDescAudioPath}`}
                                  type="audio/ogg"
                                />
                                <source
                                  // src={element?.teacherDescAudioPath}
                                  src={`${FILE_BASE_URL}${element?.teacherDescAudioPath}`}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the
                                mashqAudioPath element.
                              </audio>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <h6
                          className="fw-bold mb-4 "
                          style={{ textDecoration: "underline" }}
                        >
                          Mashq Details
                        </h6>
                        {element?.mashqs && element?.mashqs.length > 0
                          ? element?.mashqs.map((sub, index) => {
                            return (
                              <>
                                <div
                                  key={"test" + index}
                                  className="col-md-6 mb-3"
                                >
                                  <div>
                                    <label htmlFor="" className="key">
                                      Mashq Arabic
                                    </label>
                                  </div>
                                  <div>
                                    <label htmlFor="" className="value">
                                      {sub?.mashq}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <div>
                                    <label htmlFor="" className="key">
                                      Mashq Audio(Teacher And Student)
                                    </label>
                                  </div>
                                  <div>
                                    {sub?.mashqAudioPath ? (
                                      <audio
                                        controls
                                        style={{
                                          width: "100%",
                                          height: "38px",
                                        }}
                                      >
                                        <source
                                          // src={sub?.mashqAudioPath}
                                          src={`${FILE_BASE_URL}${sub?.mashqAudioPath}`}
                                          type="audio/ogg"
                                        />
                                        <source
                                          // src={sub?.mashqAudioPath}
                                          src={`${FILE_BASE_URL}${sub?.mashqAudioPath}`}
                                          type="audio/mpeg"
                                        />
                                        Your browser does not support the
                                        mashqAudioPath element.
                                      </audio>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3"></div>
                                <div className="col-md-6 mb-3">
                                  <div>
                                    <label htmlFor="" className="key">
                                      Mashq Audio(Teacher )
                                    </label>
                                  </div>
                                  <div>
                                    {sub?.teacherMashqAudioPath? (
                                      <audio
                                        controls
                                        style={{
                                          width: "100%",
                                          height: "38px",
                                        }}
                                      >
                                        <source
                                          // src={sub?.mashqAudioPath}
                                          src={`${FILE_BASE_URL}${sub?.
                                            teacherMashqAudioPath
                                            }`}
                                          type="audio/ogg"
                                        />
                                        <source
                                          // src={sub?.mashqAudioPath}
                                          src={`${FILE_BASE_URL}${sub?.
                                            teacherMashqAudioPath
                                            }`}
                                          type="audio/mpeg"
                                        />
                                        Your browser does not support the
                                        mashqAudioPath element.
                                      </audio>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {/* harf ViewDetails  */}
                                {sub?.harf?.harf_name ? (
                                  <div className="col-md-6 mb-3">
                                    <div>
                                      <label htmlFor="" className="key">
                                        harf
                                      </label>
                                    </div>
                                    <div>
                                      <label htmlFor="">
                                        {sub?.harf?.harf_name}
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-6 mb-3"></div>
                                )}

                                <div className="col-md-6 mb-3"></div>
                              </>
                            );
                          })
                          : ""}
                      </div>
                    );
                  })
                  : ""}
              </div>
              <div className="buttons-modal border-top pt-3 text-end ">
                <Link
                  to="#"
                  className="bg-danger"
                  onClick={() => {
                    handleViewClose();
                  }}
                >
                  Cancel
                </Link>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* ======================= END VIEW ========================== */}

        {loader ? (
          <FormLoader />
        ) : (
          <div className="table-wrapper">
            <h6>Total Records : {sabaqList.length}</h6>
            <Table className="display" striped bordered hover size="lg">
              <thead className="text-center">
                <tr>
                  <th>Sr. No.</th>
                  <th
                  //  style={{ textAlign: "center" }}
                  >
                    Sabaq List
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sabaqList && sabaqList.length > 0
                  ? sabaqList.slice(startIndex, endIndex).map((ele, index) => {
                    return (
                      <React.Fragment>
                        <tr key={"indexele" + index}>
                          <td>{index + 1 + startIndex}</td>
                          <td>{ele.sabaqName}</td>
                          <td className="action">
                            <Link
                              to="#"
                              className="icon edit"
                              onClick={() => {
                                // handleAddShow();
                                setIdEdit("edit");
                                setImageWidth({
                                  ...imageWidth,
                                  sabaq: "117px",
                                  des: "117px",
                                  Title: "117px",
                                  subdesc: "117px",
                                  mashq: "117px",
                                });
                                EditListApi(ele.sabaqId);
                              }}
                            >
                              <img src={ic_edit} alt="" />
                            </Link>

                            <Link
                              to="#"
                              className="icon view"
                              // onClick={handleViewShow}
                              onClick={() => {
                                handleViewShow();
                                // EditListApi(ele.sabaqId, "view");
                                // setViewDetails(viewSabaqList);
                                // setAddSabaq(filterEdit)
                                viewDetailshandle(ele.sabaqId);
                              }}
                            >
                              <img src={ic_eyes} alt="" />
                            </Link>

                            <Link
                              to="#"
                              className="icon delete"
                              onClick={() => {
                                handleDeleteShow();
                                setDeleteId(ele.sabaqId);
                              }}
                            >
                              <img src={ic_delete} alt="" />
                            </Link>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                  : ""}
              </tbody>
            </Table>

            {sabaqList && sabaqList.length > 0 ? (
              <Pagination
                changePage={changePage}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            ) : (
              <div>
                <h5 style={{ color: "red", textAlign: "center" }}>
                  {" "}
                  Records Not Found
                </h5>
              </div>
            )}
          </div>
        )}
      </main>
    </>
  );
};

export default NoraaniQaaidah;
