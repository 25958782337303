import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import { PostCallWithErrorResponse, postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { simpleGetCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
const RamzanSection = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);
  const [validated, setValidated] = useState(false);
  const [r_loader, setr_loader] = useState(true);




  const [data, setdata] = useState({})
  console.log("data>>>>",data);

  const navigate = useNavigate();
  const [isOn, setIsOn] = useState(false);

  console.log("on>>>",isOn);
  const toggle = () => {
    setIsOn((prev) => !prev);
    // setdata(isOn ? 0 : 1)
    
    Ramzan_section_update();
  };

   useEffect(() => {
    setting_listing()
     
    }, []);
 
  const [notification, setNotication] = useState({
    title: "",
    message: "",
  });


 const setting_listing = () => {
    simpleGetCallWithErrorResponse(ApiConfig.RAMZANSECTION)
      .then((res) => {
        console.log("Setting_list", res);
        setr_loader(true);

    
        
          setdata(res.json?.data.ramzan_section);
       
      })
      .catch((err) => {
        setr_loader(false);
        ToastMsg("error", "something went wrong");
      });
  };



 const Ramzan_section_update =async ()=>{
    let requestBody =JSON.stringify({
      ramzan :isOn
    })
console.log("requestBody>>",requestBody);
await PostCallWithErrorResponse(
    ApiConfig.RAMZANSECTION_UPDATE,
     requestBody
    ).then((resp)=>{
      console.log("ramzan section :",resp);
      setting_listing()
         if (resp.json.success == true) {
                ToastMsg("success", resp.json.message);
                
              } else {
                // setIsLoading(false);
                ToastMsg("error", resp.json.message);
              }
    }).catch((err)=>{
        console.log("error message",err);
    })
 }



  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="wrapper-push-notification">
        <div className="row main-section"></div>
        <div className="col-md-12 right-section">
          <h4>Ramzan Section</h4>
          <div className="mt-5">
            <div className="row jd-main">
              <main id="" className="admin-main">
                <div className="table-wrapper mt-4">
                  <table className="display table table-lg table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th style={{ textAlign: "left" }}>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {surahList.length > 0
                      ? surahList.map((item,index) => {
                        return ( */}
                      <tr>
                        <td>1</td>
                        <td style={{ textAlign: "left" }}>Ramzan Section</td>
                        <td className="action">
                          <Link
                            to="#"
                         
                            // onClick={()=>handleShowEdit(item)}
                          >
                            <button
                              onClick={toggle}
                              className={`px-4 py-2 text-white font-bold rounded-lg shadow-md transition-all ${
                                isOn ? "bg-green-500" : "bg-gray-500"
                              }`}
                            >
                            
                              {data  ? "ON" : "OFF"}
                            </button>
                          </Link>
                          {/* <Link to="#" className="icon view">
                                        <img src={ic_eyes} alt="" />
                                      </Link> */}
                        </td>
                      </tr>
                      {/* );
                      })
                      : null} */}
                    </tbody>
                  </table>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RamzanSection;
